
import { defineComponent } from 'vue'
import { urlTobase64, shake } from '@/utils/util'
import { recharge } from '@/api/home'
import global from '@/utils/global'
import { ElMessage } from 'element-plus'
import user from '@/utils/user'
import QRCode from 'qrcodejs2'
export default defineComponent({
    props: ['dataTitle', 'width', 'isCloseType', 'openBank', 'bankNo', 'bankName', 'useWay', 'lockArr'],
    data () {
        return {
            typeIndex: 0,
            num: '',
            fileValue: '' as any,
            qrcode: '' as any,
			window: window as any
        }
    },
    methods: {
        close () {
            this.$emit('close')
            this.$Bus.emit('close2', '关闭了吗')
        },
        determine () {
            this.$emit('determine', this.num, this.fileValue)
        },
        onSwitchTab (event:number) {
			if (event === 1 && this.lockArr) {
				const arr = this.lockArr.filter((item:string) => item === '微信充值')
				if (arr.length > 0) {
					this.$message.error('当前不支持' + arr[0])
					return
				}
			}
            this.typeIndex = event
            if (Number(this.typeIndex) === 1) {
				this.num = ''
                // this.getRecharge()
            }
        },
        generateQRCode (url:{codeUrl:string}) {
			this.window.document.getElementById('code').innerHTML = ''
            this.qrcode = new QRCode('code', {
                text: url.codeUrl, // 二维码所携带的数据
                width: 90, // 二维码宽度
                height: 90 // 二维码高度
            })
        },
        async getRecharge () {
            const result = await recharge({
                amount: { total: this.num },
                total: this.num,
                description: '账户充值',
                mchid: user.wechatMchId,
                useWay: this.useWay
            })
            await this.generateQRCode(result)
        },
        // 获取本地img图片
        async handleFileUpload (event:any) {
			console.log(await urlTobase64(event))
			const code:any = await urlTobase64(event)
			console.log(code.split(',')[0].split('/')[0].split(':')[1])
			const flag = code.split(',')[0].split('/')[0].split(':')[1]
			if ( flag === 'image' ) {
				this.fileValue = await urlTobase64(event)
			} else {
				console.log('false')
				ElMessage.error('请选择图片上传')
			}
        },
        onNum (event:string) {
			if (this.num.length === 0) {
				this.$message.error('请输入内容')
				return
			}
			if (Number(this.num) < 0) {
				this.$message.error('输入金额不可小于0元')
				return
			}
			// this.num = this.num.replace(/0/g, '')
            if (this.typeIndex === 1) {
                shake(():any => {
                    this.num = this.num.replace(/[^\d]/g, '').replace(/\./g, '')
                    this.getRecharge()
                })
            }
        }
    }
})
